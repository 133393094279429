import React from 'react';
import styled, { css } from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Button } from 'src/components/atoms/Button';
import { GitHubButton } from 'src/components/atoms/GitHubButton';
import { InlineList } from 'src/components/molecules/InlineList';
import { Link } from 'src/containers/Link';
import { FixedImg, FluidImg } from 'src/embeds/image/image-dato';
import { Ph, Flow, FlowGrid, Stop, Clip, Stack, Grid } from 'src/system';

const MyFlowGrid = styled(FlowGrid)`
  @media (min-width: 1024px) {
    --columns: 2;
  }
`;

const TitleCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Wrap = styled.div`
  max-width: ${({ theme }) => theme.largeContentWidth};
  margin: 0 auto;
  padding: 60px 1rem;
`;

const GalleryGrid = styled(Grid)`
  --grid-gap: 20px;
  --columns: 2;
  @media (min-width: 1024px) {
  }
`;

export default function DrawerSplitGallery({
  heading,
  preHeading,
  content,
  contentNode,
  callouts,
  ctas,
  customData,
  image,
  ...rest
}) {
  const ctaBlocks = callouts.filter((block) => block.__typename == 'DatoCmsCtaBlock');
  const galleryBlock = callouts.find((block) => block.__typename == 'DatoCmsImageGalleryBlock');
  customData = JSON.parse(customData || '{}');
  return (
    <Wrap>
      <Stack style={{ '--gap': '60px' }}>
        <h2 style={{ margin: 0, textAlign: 'center' }}>{heading}</h2>
        <MyFlowGrid style={{ '--gap-x': '40px' }}>
          <div className="cell" style={{ fontSize: '1rem' }}>
            {content.trim().length > 0 ? <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer> : null}
          </div>
          <div className="cell">
            <GalleryGrid>
              {galleryBlock.images.map((image, i) => (
                <div key={image.url} style={{ '--row-span': i == 0 ? 2 : 1 }}>
                  <FluidImg data={image} {...(i == 0 ? { aspectRatio: '2:3' } : {})} />
                </div>
              ))}
            </GalleryGrid>
          </div>
        </MyFlowGrid>
      </Stack>
    </Wrap>
  );
}
